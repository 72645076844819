import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  LightningBoltIcon,
  SparklesIcon,
  CalendarIcon,
  ClipboardCheckIcon,
  CollectionIcon,
  CheckCircleIcon,
} from '@heroicons/react/outline';
import Layout from '../../components/layout';
import BenefitHero from '../../components/Benefit/BenefitHero';
import BenefitFeatures from '../../components/Benefit/BenefitFeatures';
import BenefitFeaturesImage from '../../components/Benefit/BenefitFeaturesImage';
import BenefitCta from '../../components/Benefit/BenefitCta';
import BenefitFeaturesTwoColumn from '../../components/Benefit/BenefitFeaturesTwoColumn';
import BookDemo from '../../components/BookDemo';
import { SEO } from '../../components/SEO/SEO';

const FeaturesList = [
  {
    name: 'Maak een overzichtsplanning',
    description:
      'Plan meerdere projecten tegelijk in tot op detailniveau. Zo kun je zien wat de totale doorlooptijd is en welke activiteiten overlappen en/of afhankelijk van elkaar zijn.',
    icon: CalendarIcon,
  },
  {
    name: 'Maak materiaalbriefjes aan',
    description:
      'Geef je projectleden inzicht in de planning. Zo hoef je de planning niet meer te printen of te mailen. Wanneer jij een aanpassing doet is deze direct voor iedereen inzichtelijk. ',
    icon: CollectionIcon,
  },
  {
    name: 'Altijd up-to-date',
    description:
      'Leg afspraken vast met je projectleden. Deel de informatie met klant, onderaannemer en medewerkers. Zo is iedereen atijd up-to-date.',
    icon: LightningBoltIcon,
  },
  {
    name: 'Digitale werkbonnen',
    description:
      'Maak digitale werkbobnnen aan en wijs ze toe aan de bijbehorende projectleden. Zo weet iedereen, altijd wat er gebeuren moet en welke materialen er nodig zijn.',
    icon: ClipboardCheckIcon,
  },
];

const PlanPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Plan' }} />
    <BenefitHero
      heading={
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">Plan je projecten </span>{' '}
          <span className="block text-yellow-400 xl:inline">eenvoudig</span>
        </h1>
      }
      subheading="
        Met Homigo plan je je personeel en je projecten eenvoudig in. Je
          projectleden kunnen wijzigingen direct zien, waardoor er geen
          miscommunicatie onstaat."
    />

    <BenefitFeatures
      title="projectplanning"
      heading="Bespaar tijd en faalkosten"
      subheading="Behoud het overzicht over meerdere projecten tegelijk, door alles binnen Homigo in te plannen."
      features={FeaturesList}
    />

    <BenefitFeaturesImage
      title="Altijd beschikbaar"
      heading="Slimmer werken? Geen probleem."
      subheading="Homigo helpt jou al je projecten eenvoudiger in te plannen. Doordat je het overzicht behoudt en real time de planning kunt wijzigen, bespaar je een hoop tijd en gedoe. Deel je planning met al je projectleden en wijs activiteiten toe. Slimmer werken was nog nooit zo makkelijk!"
      image={
        <StaticImage
          src="../../img/plan_large.png"
          alt=""
          className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
        />
      }
    />

    <BenefitCta
      heading={
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Overzicht en structuur?</span>
          <span className="">Download</span>
          <span className="text-primary"> Homigo </span>
          <span className="">vandaag nog.</span>
        </h2>
      }
      subheading="Homigo is verkrijgbaar voor zowel IOS als Android en is ook te gebruiken als webversie op je computer."
    />

    <BenefitFeaturesTwoColumn
      iconOne={<SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />}
      headerOne="Overzicht en duidelijkheid"
      paragraphOne="Geen miscommunicaties meer op de werkvloer. Met de planner van Homigo heeft iedereen direct overzicht en duidelijkheid. Verschuif projecten heel eenvoudig in het overzicht en deel het met al je projectleden. Ook kan je meerdere planningen tegelijk maken."
      quote="Het was ontzettend fijn dat onze aannemer met Homigo
                    werkte. Wij hadden altijd inzage in de planning en dat gaf
                    ons rust."
      quoteName="Marijke de Vries, Huiseigenaar"
      imageOne={<StaticImage src="../../img/plan_iphone.png" alt="" className="" />}
      iconTwo={<CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />}
      headerTwo="Afspraken vastleggen"
      paragraphTwo="Met Homigo laat je je projectleden eenvoudig hun uren, materiaal of meerwerk registreren. Dit geeft jou volledig overzicht over het gehele project waardoor je meer grip en controle ervaart. Ook weet iedereen waar hij aan toe is en bespaar je tijd met uitleggen."
      imageTwo={
        <StaticImage src="../../img/plan_desktop.png" alt="Customer profile user interface" />
      }
    />

    <BookDemo />
  </Layout>
);

export default PlanPage;
